import { Injectable, inject } from '@angular/core';
import { catchError, filter, first, forkJoin, from, map, of, switchMap, tap } from 'rxjs';
import { createEffect, ofType } from '@ngrx/effects';

import { BaseEffect } from '../base.effect';
import { AuthenticationService } from '@Authentication/auth-extras/authentication.service';
import { AuthStore } from './auth.reducer';
import { StorageService } from '@Services/storage.service';

@Injectable()
export class AuthEffect extends BaseEffect {
  protected service = inject(AuthenticationService);
  protected sS = inject(StorageService);

  getAuthUserFromLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getAuthUserL),
      switchMap(() => forkJoin([this.service.getAuthFromLocal(), this.service.getUserFromLocal()])),
      map((r) => ({ auth: r[0], userProfile: r[1] })),
      map((action) =>
        action.userProfile ? AuthStore.actions.setAuthUser(action) : AuthStore.actions.getAuthUserLError(),
      ),
    ),
  );

  getWebUserFromLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getWebUserL),
      switchMap(() => this.service.getWebUserFromLocal()),
      map((r) => ({ webUser: r })),
      map((action) =>
        action.webUser ? AuthStore.actions.setWebUser(action) : AuthStore.actions.getWebUserLError(),
      ),
    ),
  );

  checkUser = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.setUser, AuthStore.actions.setAuthUser),
      switchMap((action) =>
        of(this.service.checkUserProfile(action.userProfile)).pipe(
          map(() => AuthStore.actions.checkUserSuccess()),
          catchError((e) => of(AuthStore.actions.logout({}))),
        ),
      ),
    ),
  );

  logout = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.logout),
      switchMap((action) => from(this.service.logout(action.redirectRoute))),
      map((action) => AuthStore.actions.logoutSuccess()),
    ),
  );

  fetchUserStateDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.setUser, AuthStore.actions.setAuthUser),
      map((action) => AuthStore.actions.fetchUserStateDetails(action)),
    ),
  );

  getUserFromOnline = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getUserO),
      switchMap((action) =>
        action.userID
          ? of(action.userID)
          : this.store.select(AuthStore.selectors.userProfile).pipe(
              first((x) => !!x),
              map((r) => r.users.id),
            ),
      ),
      filter((userID) => !!userID),
      switchMap((userID) => this.service.getProfileFromOnlineByID(userID)),
      map((action) => AuthStore.actions.getUserOSucces({ userProfile: action })),
    ),
  );

  saveUserToLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getUserOSucces),
      switchMap((action) => this.service.saveProfileToLocal(action.userProfile)),
      map(() => AuthStore.actions.saveUserToLocalSuccess()),
    ),
  );
}

import { Component, Injectable, inject } from '@angular/core';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { Subject } from 'rxjs';
import { TranslationService } from '../../Services/translation.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class MyCustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  readonly translationService = inject(TranslationService);
  readonly cache: { [key: string]: string } = {};

  constructor() {
    console.log('trans inited');
    setTimeout(() => {
      let fields = [
        'firstPageLabel',
        'itemsPerPageLabel',
        'lastPageLabel',
        'nextPageLabel',
        'previousPageLabel',
        'pageEqual1',
      ] as const;
      if (environment.translate)
        this.translationService
          .translateList(
            fields.map((x) => ({ field: x, value: this['_' + x] })),
            {
              selectorField: 'value',
            },
          )
          .subscribe((r) => {
            console.log('trans local', r);
            r.forEach((x) => {
              this[x.field] = x.value;
            });
            this.changes.next();
          });
      else {
        fields.forEach((x) => (this[x] = this['_' + x]));
        this.changes.next();
      }
    }, 1000);
  }

  // For internationalization, the `$localize` function from
  // the `@angular/localize` package can be used.
  readonly _firstPageLabel = `First page` as const;
  firstPageLabel = ``;
  //   firstPageLabel = $localize`First page`;
  readonly _itemsPerPageLabel = `Items per page:` as const;
  itemsPerPageLabel = ``;
  //   itemsPerPageLabel = $localize`Items per page:`;
  readonly _lastPageLabel = `Last page` as const;
  lastPageLabel = ``;
  //   lastPageLabel = $localize`Last page`;

  // You can set labels to an arbitrary string too, or dynamically compute
  // it through other third-party internationalization libraries.
  readonly _nextPageLabel = 'Next page' as const;
  nextPageLabel = '';
  readonly _previousPageLabel = 'Previous page' as const;
  previousPageLabel = '';

  readonly _pageEqual1 = `Page 1 of 1` as const;
  pageEqual1 = ``;

  translate(text: string) {
    this.cache[text] = '';
    this.translationService.pipeTransformer(text).subscribe((r) => {
      this.cache[text] = r;
      this.changes.next()
    });
  }

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.pageEqual1;
      //   return $localize`Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    const ret = `Page ${page + 1} of ${amountPages}`;
    if (environment.translate) {
      if (this.cache[ret]) return this.cache[ret];
      else {
        this.translate(ret);
        return '';
      }
    } else return ret;
    // return $localize`Page ${page + 1} of ${amountPages}`;
  }
}

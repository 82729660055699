user-help-help {
  display: block;

  .expanderButton {
    // position: absolute;
    // z-index: 1;
    // right: 0;
    // top: 0;
    // bottom: 0;
  }

  .wsp {
    white-space: normal;
  }

  .help-item-link {
    margin: 4px 0;
    height: auto !important;
    min-height: var(--mdc-list-list-item-one-line-container-height, 48px);

    .help-item-link-content {
      white-space: normal;
    }
  }
}

html {
  --accentt: orange;
}

.user-help-modal {
  .mat-mdc-dialog-surface {
    padding: 12px;

    modal-header .mat-mdc-dialog-title {
      background-color: var(--primary);
      padding: 5px 10px !important;
      border-radius: 8px;
      box-shadow: 0 0 20px #00000096;
      color: #fff;
    }

    .mat-mdc-dialog-content {
      // width: var(--width, 100%)
      max-height: calc(80vh - 80px) !important;
    }

    .size- {

      &minimized {
        .mat-mdc-dialog-content {
          display: none;
        }
      }

      &full-view {
        --width: 90%
      }

      &half-view {
        --width: 50%
      }

      &third-view {
        --width: 30%
      }

    }
  }
}


.size-button {
  display: flex;
  gap: 8px;

  .size-icon-cont {
    --ddmein: 15px;
    height: var(--ddmein);
    width: var(--ddmein);
    border: 1px solid var(--accentt);
    display: inline-flex;
    border-radius: 5px;
    overflow: auto;

    .size-icon-inner {
      height: 100%;

      background-color: var(--accentt);
    }
  }
}
<mat-nav-list>
  <div class="mb-4">
    <loader [loading]="loading()" class="vh-10">
      @if (!hasList()) {
        <div class="text-center">
          <div class="wsp">{{ 'Resources were not found for this page' | appTranslateNL | async }}</div>
        </div>
      }
      @for (item of list(); track item.content?.id) {
        <div class="position-relative d-flex align-items-center gap-1">
          <a
            class="w-100 pointer help-item-link"
            mat-list-item
            (click)="chooseItem(item.content, item.reference); previewModal.open()">
            <div class="help-item-link-content">
              {{ item.content.title | appTranslateNL | async }}
            </div>
            <!-- <button mat-icon-button matSuffix class="expanderButton">
              <i class="fa fa-expand text-light"></i>
            </button> -->
          </a>
        </div>
      }
    </loader>
  </div>
  <mat-divider />
  <!-- <a
    mat-list-item
    class="mt-3"
    [ngClass]="{ 'text-center': loading() || !hasList() }"
    routerLink="/help"
    (click)="service.toggle()">
    {{ 'View More Resources' | appTranslate | async }}
  </a> -->
  <a
    mat-list-item
    class="mt-3 pointer"
    [ngClass]="{ 'text-center': loading() || !hasList() }"
    (click)="helpModal.open();service.toggle();">
    {{ 'View More Resources' | appTranslateNL | async }}
  </a>
</mat-nav-list>
<!-- @if (contentQuery(); as contentQuery) {
  <div class="">
    <view-cms-resource [query]="contentQuery" [allowEdit]="false" />
  </div>
} -->

<ng-template #headerBtnTemplate>
  <button [matMenuTriggerFor]="sizeMenu" class="btn">
    <i class="fa fa-cogs" aria-hidden="true"></i>
  </button>
  <mat-menu #sizeMenu="matMenu" xPosition="after">
    @for (item of sizes(); track $index) {
      <button
        mat-menu-item
        [ngClass]="{ 'text-primary': sizeState() == item.code }"
        (click)="sizeState.set(item.code)"
        class="size-button">
        <span class="size-icon-cont">
          <span class="size-icon-inner" [style.width.%]="item.width"></span>
        </span>
        {{ item.title | titlecase | appTranslateNL | async }}
      </button>
    }
  </mat-menu>
</ng-template>

<modal-comp
  #previewModal
  width="30%"
  height="auto"
  [baseConfig]="baseconfig()"
  [isDraggable]="true"
  [hasBackdrop]="false"
  containerClass="size-{{ sizeState() }}"
  [headerBtnTemplateRef]="headerBtnTemplate"
  [showFooter]="false"
  header="Help Resource">
  <ng-template modalBody>
    <view-cms-resource [query]="contentQuery()" [allowEdit]="false" [hideOutline]="true" />
  </ng-template>
</modal-comp>

<ng-template #modalPrefixTempl>  
  <button mat-icon-button class="text-light" (click)="toggleSideMenu()">  <mat-icon>menu</mat-icon>  
  </button>
</ng-template>

<ng-template #explorerHeaderBtnTemplate>
  <button (click)="goHome()" class="btn">
    <i class="fa fa-search" aria-hidden="true"></i>
  </button>
  <button [matMenuTriggerFor]="sizeMenu" class="btn">
    <i class="fa fa-cogs" aria-hidden="true"></i>
  </button>
  <mat-menu #sizeMenu="matMenu" xPosition="after">
    @for (item of sizes(); track $index) {
      <button
        mat-menu-item
        [ngClass]="{ 'text-primary': sizeState() == item.code }"
        (click)="sizeState.set(item.code)"
        class="size-button">
        <span class="size-icon-cont">
          <span class="size-icon-inner" [style.width.%]="item.width"></span>
        </span>
        {{ item.title | titlecase | appTranslateNL | async }}
      </button>
    }
  </mat-menu>
</ng-template>
<modal-comp
  #helpModal
  width="30%"
  [headerPrefixTemplateRef]="modalPrefixTempl"
  height="80vh"
  [baseConfig]="baseconfig()"
  [isDraggable]="true"
  [hasBackdrop]="false"
  containerClass="size-{{ sizeState() }}"
  [headerBtnTemplateRef]="explorerHeaderBtnTemplate"
  [showFooter]="false"
  header="Help Explorer">
  <ng-template modalBody>
    @if (isJupiter()) {
      <admin-app-admin-help class="h-100" #explorer [mini]="true" />
    } @else {
      <coreapp-client-help class="h-100" #explorer [mini]="true" />
    }
  </ng-template>
</modal-comp>

import { Type } from '@angular/core';
import { Observable } from 'rxjs';
import { ICMSContent, ICMSReference } from '../../../../Reusables/reusable-pages/cms/cms.model';
import { UserHelperModuleComponent } from './user-helper-module.component';
import { IGetQuery } from '../../../models/index.model';

export interface IUserHelperModule {
  label: string;
  route?: string;
  link?: string;
  action?: () => any;
  component?: Type<UserHelperModuleComponent>;
  icon: string;
  id: EUserHelperID;
  badge?: string | number;
}
export interface IUserHelperConfig {
  hiddenModules?: { [k in EUserHelperID]?: boolean };
  hide?: boolean;
  applicationCode?: string;
  moduleProperties?: { [k in EUserHelperID]?: Partial<IUserHelperModule> };
  searchHelpFunction?: (query: { path: string }) => Observable<
    {
      content: ICMSContent;
      reference: ICMSReference;
    }[]
  >;
  searchTasksFunction?: (query: { pageNumber: number; pageSize: number }) => Observable<
    {
      title: string;
      id: number;
    }[]
  >;
  viewTaskRoute?: string;
}

export enum EUserHelperID {
  chat = 'CHAT',
  help = 'HELP',
  quote = 'QUOTE',
  note = 'NOTE',
  task = 'TASK',
  notification = 'NOTIFICATION',
  dictionary = 'DICTIONARY',
}

@use '@angular/material' as mat;

user-helper {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;

  &>div {
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 111;
    --mat-sidenav-container-width: 200px;
    --mat-sidenav-container-background-color: none;
    --color: #fff;
    --mat-divider-color: #ffffff4f;
    --mat-sidenav-container-text-color: var(--color);
    --mat-sidenav-content-text-color: var(--color);
    @include mat.list-overrides((list-item-label-text-color: var(--color), list-item-hover-label-text-color: #ffffffde));

    .user-helper-nav-list {
      .mat-badge-after .mat-badge-content {
        left: 89%;
      }
    }

    .uh-icon {
      --dimen: 50px;
      height: var(--dimen);
      width: var(--dimen);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--border-radius);
      color: var(--color);
      transition: 0.4s linear margin;

      &:hover,
      &:focus {
        margin-bottom: 10px;
      }
    }

    .uh-icon,
    .uh-cont {
      // background-color: #fff;
      // background-color: var(--primary);
      background-image: linear-gradient(225deg, #00000069, transparent);

      box-shadow: 0 0 20px #00000043;
    }

    .uh-cont {
      min-height: 400px;
      min-width: var(--mat-sidenav-container-width);
      border-radius: var(--border-radius);
      transition: 1s linear width;
      position: relative;
      color: #fff;

      &.opened {
        width: 500px;
      }

      mat-sidenav-container {
        --mat-sidenav-content-background-color: transparent;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        --padding: 20px;

        mat-sidenav {
          /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
           causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
          flex: 1;

          .mat-drawer-inner-container {
            padding: var(--padding);
            padding-right: 0;
            --mat-list-active-indicator-shape: var(--border-radius) 0 0 var(--border-radius);

            .header-cont {
              padding-right: var(--padding);

              hr {
                margin: 0;
                border: 3px solid;
                border-radius: var(--border-radius);
              }

              .mat-mdc-icon-button.mat-mdc-button-base {
                --mdc-icon-button-state-layer-size: 20px;
                padding: 0;
              }

              .mat-mdc-icon-button .mat-mdc-button-touch-target {
                --mdc-icon-button-state-layer-size: 20px;
                height: var(--mdc-icon-button-state-layer-size);
                width: var(--mdc-icon-button-state-layer-size);
              }

              .dragger {
                cursor: grab;
              }
            }

            .active-m {
              @include mat.list-overrides((list-item-label-text-color: var(--primary), list-item-hover-label-text-color: var(--primary)));
              background-color: #fff;
              background-image: linear-gradient(90deg, #fff, #fff, #fff, #ffffffc9, #ffffffb3, #ffffff6c, transparent);
            }
          }
        }


        mat-sidenav-content {
          margin-right: var(--mat-sidenav-container-width) !important;
          padding: var(--padding);
        }
      }

      .example-is-mobile {
        mat-sidenav {
          /* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the
           `<body>` to be our scrolling element for mobile layouts. */
          flex: 1 0 auto;
        }

        mat-sidenav-content {
          margin-right: 0 !important;
        }
      }
    }

  }

  .mat-badge-content {
    --mat-badge-background-color: #d50000;
    background-image: linear-gradient(224deg, #00000066, transparent);
  }

  --mat-list-active-indicator-shape: var(--border-radius);
}
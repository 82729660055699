import { Component } from '@angular/core';
import { SharedModule } from '../../../../shared.module';
import { UserHelperModuleComponent } from '../user-helper-module.component';
import { rxResource } from '@angular/core/rxjs-interop';

@Component({
  selector: 'user-help-task',
  imports: [SharedModule],
  templateUrl: './user-help-task.component.html',
  styleUrl: './user-help-task.component.scss',
})
export class UserHelpTaskComponent  extends UserHelperModuleComponent {
  // protected readonly listResource = rxResource({
  //   request: () => { 
  //     return {
  //       searchHelpFunction: this.service.config().searchHelpFunction || this.service.searchHelp,
  //       path: location.pathname,
  //     };
  //   },
  //   loader: ({ request }) =>
  //     request.searchHelpFunction({ path: request.path })
  // });
  // readonly loading = computed(() => this.listResource.isLoading());
  // readonly list = computed(() =>
  //   this.listResource.value()?.map((x) => ({ ...x, query: { refId: x.reference.refId } })),
  // );
  // readonly hasList = computed(() => this.listResource.hasValue());
  // readonly content = signal<ICMSContent>(null);
  // readonly contentQuery = linkedSignal<ICMSResourceQuery>(() =>
  //   this.content() ? { helpGuideContentId: this.content()?.id } : null,
  // );

  // readonly helpMargin = signal('10px');
  // readonly baseconfig = computed<MatDialogConfig>(() => ({
  //   position: { right: this.helpMargin(), bottom: this.helpMargin() },
  //   panelClass: 'user-help-modal',
  // }));
  // readonly ESizeState = ESizeState;
  // readonly sizeState = signal(ESizeState.third);
  // readonly sizes = computed(() => {
  //   const arr = Object.values(ESizeState),
  //     length = arr.length;

  //   return arr.map((x, index) => ({
  //     code: x,
  //     title: x.split('-').join(' '),
  //     width: index == length - 1 ? 100 : (index / length) * 100,
  //   }));
  // });

  // readonly isJupiter = computed(() => environment.isJupiter);

  // private readonly sizeSateEffect = effect(() => {
  //   const sizeState = this.sizeState();
  //   const doc = document.querySelector<HTMLDivElement>('.user-help-modal');
  //   if (doc) {
  //     let width = 'auto';
  //     if (sizeState == ESizeState.full) width = `calc(100% - ( 2 * ${this.helpMargin()}))`;
  //     if (sizeState == ESizeState.half) width = '50%';
  //     if (sizeState == ESizeState.third) width = '30%';
  //     // else width = '20%';
  //     doc.style.width = width;
  //   }
  // });

  // readonly helpModalRef = viewChild<ModalComponent>('helpModal');
  // readonly explorerRef = viewChild<CMSResourceExoplorerParentBaseComponent>('explorer');

  // ngOnInit(): void {
  //   //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
  //   //Add 'implements OnInit' to the class.
  // }

  // ngAfterViewInit(): void {
  //   //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
  //   //Add 'implements AfterViewInit' to the class.
  //   // setTimeout(() => {
  //   //   this.helpModalRef().open();
  //   // }, 500);
  // }

  // refresh() {
  //   this.listResource.reload();
  // }

  // chooseItem(content: ICMSContent, reference: ICMSReference) {
  //   this.content.set(content);
  //   this.contentQuery.set(reference);
  //   this.service.toggle();
  //   this.sizeState.set(ESizeState.third);
  // }

  // toggleSideMenu() {
  //   const explorerRef = this.explorerRef();
  //   explorerRef.toggleSideMenu();
  //   // debugger;
  // }

  // goHome() {
  //   const explorerRef = this.explorerRef();
  //   explorerRef.goHome();
  //   // debugger;
  // }
}
 